import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { landingPageContainerWideWidth, slimWidth } from "../constants";

export const LandingPageContainerWide = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  width: "calc(100vw - 2rem)",
  left: "calc(50% - 1rem)",
  transform: "translateX(-50%)",
  height: "100%",
  maxWidth: landingPageContainerWideWidth,
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.success.veryLight,
  borderRadius: "1rem",
  borderWidth: "0px",
  padding: "4rem",
  [theme.breakpoints.down(slimWidth)]: {
    padding: "4rem 1rem",
  },
  overflow: "hidden",
  marginLeft: "1rem",
  marginRight: "1rem",
  marginBottom: "4rem",
  marginTop: "4rem",
}));
