import { createTheme } from "@mui/material/styles";
import { green, grey, red, yellow, indigo } from "@mui/material/colors";
import { slimWidth } from "./constants";

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "Birch",
    ].join(","),
    h1: {
      fontSize: 38,
      marginBottom: "0.8rem",
      textAlign: "left",
      fontFamily: "Helvetica",
      fontWeight: "bold",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
    h2: {
      fontSize: 28,
      marginBottom: "0.8rem",
      textAlign: "left",
      fontFamily: "Helvetica",
      fontWeight: "bold",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
    h3: {
      fontFamily: "Helvetica",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "left",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
    h4: {
      fontFamily: "Helvetica",
      fontSize: 20,
      textAlign: "left",
      maxWidth: slimWidth,
    },
    h5: {
      fontFamily: "Helvetica",
      fontSize: 18,
      textAlign: "left",
      maxWidth: slimWidth,
    },
    h6: {
      fontFamily: "Helvetica",
      fontSize: 16,
      textAlign: "left",
      maxWidth: slimWidth,
    },
    subtitle1: {
      fontSize: 22,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: 20,
      lineHeight: 1.5,
    },
    body1: {
      fontFamily: "Helvetica",
      textAlign: "left",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
    body2: {
      fontSize: 12,
      fontFamily: "Helvetica",
      textAlign: "left",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
    button: {},
  },
  palette: {
    primary: {
      darker: grey[900],
      dark: grey[700],
      main: "#000",
      light: grey[500],
      contrastText: "#fff",
    },
    secondary: {
      darker: grey[900],
      dark: grey[700],
      main: grey[500],
      light: grey[300],
      contrastText: "#fff",
    },
    error: {
      darker: red[900],
      dark: red[700],
      main: red[500],
      light: red[300],
      contrastText: "#fff",
    },
    warning: {
      darker: yellow[900],
      dark: yellow[700],
      main: yellow[500],
      light: yellow[300],
      contrastText: "#000",
    },
    info: {
      darker: indigo[900],
      dark: indigo[700],
      main: indigo[500],
      light: indigo[300],
      veryLight: indigo[100],
      contrastText: "#fff",
    },
    success: {
      darker: green[900],
      dark: green[700],
      main: green[500],
      light: green[300],
      veryLight: green[100],
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfc",
      secondary: grey[100],
      paper: "#fff",
      white: "#fff",
      info: indigo[100],
      light: grey[50],
      dark: grey[900],
    },
  },
  shape: {
    borderRadius: "8px",
  },
});

export default theme;
