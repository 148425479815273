import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, Box, Typography } from "@mui/material";
import { ModalContentContainer } from "../atoms/ModalContentContainer";
import {
  getAudioProject,
  renderAudioProject,
  updatePauseSegment,
  deleteSegment,
} from "../api/audio_project";
import {
  generateVoiceSegment,
  saveAudioProjectToEpisode,
} from "../api/audio_project";
import AudioSegment from "./AudioSegment";
import theme from "../theme";
import { styled } from "@mui/material/styles";
import UsageCircularProgress from "./UsageCircularProgress";
import UserContext from "../contexts/UserContext";

const StyledAudio = styled("audio")({
  flexGrow: 1,
  height: "30px",
});

export default function AudioProjectModal({
  episode,
  open,
  handleClose,
  fetchEpisodes,
}) {
  const { loadUser } = useContext(UserContext);

  const [audioProject, setAudioProject] = useState(null);
  const [audioProjectHasChanges, setAudioProjectHasChanges] = useState(false);
  const [anyActionInProgress, setAnyActionInProgress] = useState(false);
  const [isRendering, setIsRendering] = useState(false);

  const onRenderAudioProject = () => {
    setAnyActionInProgress(true);
    setIsRendering(true);
    renderAudioProject(episode.uuid, audioProject.uuid).then((result) => {
      setAudioProject(result);
      setAudioProjectHasChanges(false);
      setAnyActionInProgress(false);
      setIsRendering(false);
    });
  };

  const onGenerateSegment = (segmentIndex, segmentText, segmentVoiceId) => {
    setAnyActionInProgress(true);
    return generateVoiceSegment(episode.uuid, audioProject.uuid, {
      segment_text: segmentText,
      segment_voice_id: segmentVoiceId,
      segment_index: segmentIndex,
    })
      .then((result) => {
        setAudioProject(result);
        setAudioProjectHasChanges(true);
        setAnyActionInProgress(false);
      })
      .catch((error) => {
        console.error("Error generating segment", error);
      });
  };

  const onUpdatePauseSegment = (segmentIndex, segmentDurationSeconds) => {
    setAnyActionInProgress(true);
    updatePauseSegment(episode.uuid, audioProject.uuid, {
      segment_index: segmentIndex,
      segment_duration_seconds: segmentDurationSeconds,
    })
      .then((result) => {
        setAudioProject(result);
        setAudioProjectHasChanges(true);
        setAnyActionInProgress(false);
      })
      .catch((error) => {
        console.error("Error updating pause segment", error);
      });
  };

  const onDelete = (segmentIndex) => {
    setAnyActionInProgress(true);
    deleteSegment(episode.uuid, audioProject.uuid, {
      segment_index: segmentIndex,
    })
      .then((result) => {
        setAudioProject(result);
        setAudioProjectHasChanges(true);
        setAnyActionInProgress(false);
      })
      .catch((error) => {
        console.error("Error deleting segment", error);
      });
  };

  useEffect(() => {
    if (open) {
      setAnyActionInProgress(true);
      getAudioProject(episode.uuid)
        .then((result) => {
          setAudioProject(result);
          setAudioProjectHasChanges(false);
          setAnyActionInProgress(false);
        })
        .catch((error) => {
          setAudioProject(null);
          handleClose();
        });
    }
  }, [open, episode, handleClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContentContainer>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Audio Editor
        </Typography>
        <Box
          sx={{
            mt: "1rem",
            height: "100%",
            width: "100%",
            overflowY: "auto",
            padding: "1rem",
            backgroundColor: theme.palette.background.secondary,
            borderRadius: "0.5rem",
          }}
        >
          {audioProject?.audio_segments &&
            Object.entries(audioProject.audio_segments).map(
              ([index, segment]) => (
                <AudioSegment
                  key={index}
                  segment={segment}
                  segmentIndex={index}
                  onGenerateSegment={onGenerateSegment}
                  onUpdatePauseSegment={onUpdatePauseSegment}
                  onDelete={onDelete}
                  anyActionInProgress={anyActionInProgress}
                  setAnyActionInProgress={setAnyActionInProgress}
                />
              )
            )}
        </Box>

        <Box sx={{ width: "100%", mt: "1rem", mb: "0.3rem" }}>
          <UsageCircularProgress
            usageCount={audioProject?.usage_count}
            usageLimit={audioProject?.usage_limit}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {audioProject?.is_rendered ? (
            <StyledAudio
              controls
              style={{ width: "100%" }}
              key={audioProject.audio_url}
            >
              <source src={audioProject.audio_url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </StyledAudio>
          ) : (
            <StyledAudio
              controls
              disabled
              style={{ width: "100%", opacity: 0.5, pointerEvents: "none" }}
              key={episode.audio_url}
            >
              <source src={episode.audio_url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </StyledAudio>
          )}

          <Button
            variant="contained"
            color="primary"
            sx={{ flexShrink: 0 }}
            onClick={onRenderAudioProject}
            disabled={anyActionInProgress || !audioProjectHasChanges}
          >
            {isRendering ? "Rendering..." : "Render episode"}
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => handleClose()}
          >
            {"Discard edits"}
          </Button>
          <Button
            type="button"
            variant="contained"
            disabled={anyActionInProgress || !audioProject?.is_rendered}
            onClick={() => {
              saveAudioProjectToEpisode(episode.uuid, audioProject.uuid).then(
                () => {
                  handleClose();
                  fetchEpisodes();
                  loadUser();
                }
              );
            }}
          >
            {"Apply edits"}
          </Button>
        </Box>
      </ModalContentContainer>
    </Modal>
  );
}
