import React, { useState } from "react";
import {
  Typography,
  IconButton,
  CardContent,
  FormControlLabel,
  Switch,
  Box,
  Skeleton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { StyledCard } from "../atoms/StyledCard";
import SettingsIcon from "@mui/icons-material/Settings";
import AudioProjectModal from "./AudioProjectModal";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import {
  BASE_URL,
  MAX_EPISODE_TITLE_LENGTH,
  MAX_EPISODE_SUBTITLE_LENGTH,
  ELEVEN_LABS_VOICE_NAMES,
} from "../constants";
import LinkIcon from "@mui/icons-material/Link";

const MAX_TEXT_LENGTH = 150;

const FormItem = styled(Box)({
  marginTop: "1rem",
  width: "100%",
});

const AudioPlayerContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "1rem",
});

const StyledAudio = styled("audio")({
  flexGrow: 1,
  marginRight: "16px",
  height: "30px",
});

const AudioSkeleton = styled(Skeleton)({
  flexGrow: 1,
  marginRight: "16px",
  height: "30px",
});

const ExpandToggleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
  marginTop: "0.5rem",
  display: "block",

  "&:hover": {
    textDecoration: "underline",
  },
}));

const EpisodeTextBackground = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  padding: "1rem",
  borderRadius: "8px",
}));

const AudioControlsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
}));

function EpisodeTextContainer({ episode, isExpanded, setIsExpanded }) {
  return (
    <>
      <EpisodeTextBackground>
        <Typography
          variant="body2"
          sx={{ marginBottom: "0.5rem", fontWeight: "bold" }}
        >
          Podcast text:
        </Typography>

        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
          {episode.text.length > MAX_TEXT_LENGTH ? (
            <>
              {isExpanded
                ? episode.text
                : `${episode.text.substring(0, MAX_TEXT_LENGTH)}...`}
            </>
          ) : (
            episode.text
          )}
          {episode.text.length > MAX_TEXT_LENGTH && (
            <>
              <br />
              <ExpandToggleText
                variant="body2"
                component="span"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? "Show less" : "Show more"}
              </ExpandToggleText>
            </>
          )}
        </Typography>
      </EpisodeTextBackground>
    </>
  );
}

function EditEpisodeForm({ episode, handleUpdateEpisode, onClose }) {
  const [title, setTitle] = useState(episode.title);
  const [subtitle, setSubtitle] = useState(episode.subtitle || "");
  const [summary, setSummary] = useState(episode.summary || "");

  const handleSubmit = (event) => {
    event.preventDefault();
    handleUpdateEpisode(episode, { title, subtitle, summary });
    onClose();
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      <FormItem>
        <TextField
          required
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          helperText={`${title.length}/${MAX_EPISODE_TITLE_LENGTH}`}
        />
      </FormItem>
      <FormItem>
        <TextField
          label="Subtitle"
          type="text"
          fullWidth
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
          helperText={`${subtitle.length}/${MAX_EPISODE_SUBTITLE_LENGTH}`}
        />
      </FormItem>
      <FormItem>
        <TextField
          label="Summary"
          type="text"
          multiline
          fullWidth
          minRows={4}
          maxRows={20}
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
        />
      </FormItem>
      <FormItem sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained">
          Save Changes
        </Button>
      </FormItem>
    </Box>
  );
}

export default function ExistingEpisode({
  episode,
  onDelete,
  deletingEpisodeUuid,
  handleUpdateEpisode,
  handleUpdateEpisodeStatus,
  fetchEpisodes,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [audioProjectModalOpen, setAudioProjectModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    onDelete(episode.uuid);
    setDeleteDialogOpen(false);
  };

  const handleExpandEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleCopyLink = () => {
    if (episode.status === "PUBLISHED") {
      navigator.clipboard
        .writeText(`${BASE_URL}/episode/${episode.uuid}`)
        .then(() => {
          setTooltipOpen(true);
          setTimeout(() => setTooltipOpen(false), 2000); // Auto hide tooltip after 2 seconds
        });
    }
  };

  return (
    <StyledCard
      sx={{
        opacity: deletingEpisodeUuid === episode.uuid ? 0.5 : 1,
        borderWidth: "2px",
        borderColor:
          episode.status === "PUBLISHED" ? "success.main" : "secondary.light",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h5">{episode.title}</Typography>
              <Tooltip
                title="Link copied to clipboard!"
                open={tooltipOpen}
                disableHoverListener
                disableFocusListener
                disableTouchListener
                arrow
              >
                <IconButton
                  onClick={handleCopyLink}
                  sx={{ ml: 1 }}
                  size="small"
                  aria-label="copy link to episode"
                  disabled={episode.status !== "PUBLISHED"}
                >
                  <LinkIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            {episode.subtitle && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginBottom: "1rem" }}
              >
                {episode.subtitle}
              </Typography>
            )}
          </Box>
          {episode.status === "CREATING" ? (
            <CircularProgress color="info" size="2rem" />
          ) : (
            <FormControlLabel
              control={
                <Switch
                  checked={episode.status === "PUBLISHED"}
                  onChange={(event) =>
                    handleUpdateEpisodeStatus(episode, event)
                  }
                  color={episode.status === "PUBLISHED" ? "success" : "default"}
                />
              }
              label={episode.status === "PUBLISHED" ? "Published" : "Publish"}
              labelPlacement="start"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.8rem" } }}
            />
          )}
        </Box>
        <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
          {new Date(episode.created_date).toLocaleDateString()} -{" "}
          {ELEVEN_LABS_VOICE_NAMES[episode.voice_id]}
        </Typography>
        {episode.summary && (
          <EpisodeTextBackground sx={{ marginBottom: "1rem" }}>
            <Typography
              variant="body2"
              sx={{ marginBottom: "0.5rem", fontWeight: "bold" }}
            >
              Summary:
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
              {episode.summary}
            </Typography>
          </EpisodeTextBackground>
        )}
        <EpisodeTextContainer
          episode={episode}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        <AudioPlayerContainer>
          {episode.status === "CREATING" ? (
            <AudioSkeleton variant="text" />
          ) : (
            episode.audio_url && (
              <StyledAudio controls key={episode.audio_url}>
                <source src={episode.audio_url} type="audio/mpeg" />
                Your browser does not support the audio element.
              </StyledAudio>
            )
          )}
          <AudioControlsContainer>
            <IconButton
              sx={{ p: 1 }}
              onClick={handleExpandEditClick}
              size="small"
              aria-label="edit"
              disabled={episode.status === "CREATING"}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
            <IconButton
              sx={{ p: 1 }}
              onClick={handleDeleteClick}
              size="small"
              aria-label="delete"
              disabled={episode.status === "CREATING"}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </AudioControlsContainer>
        </AudioPlayerContainer>

        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => setAudioProjectModalOpen(true)}
            disabled={episode.status === "CREATING" || !episode.is_rendered}
            startIcon={<GraphicEqIcon />}
          >
            Audio Editor
          </Button>
        </Box>
      </CardContent>

      <AudioProjectModal
        episode={episode}
        open={audioProjectModalOpen}
        handleClose={() => setAudioProjectModalOpen(false)}
        fetchEpisodes={fetchEpisodes}
      />

      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit episode details</DialogTitle>
        <DialogContent>
          <EditEpisodeForm
            episode={episode}
            handleUpdateEpisode={handleUpdateEpisode}
            onClose={() => setEditDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Delete Episode</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the episode? This action cannot be
            cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
}
