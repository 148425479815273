import { Box, Typography } from "@mui/material";
import FeatureDemo from "./FeatureDemo.js";
import FeatureDemoSmall from "./FeatureDemoSmall.js";
import FeatureDemoSmallTwoImages from "./FeatureDemoSmallTwoImages.js";
import existingEpisodesImg from "../img/existing_episodes.webp";
import audioEditorImg from "../img/audio_editor.webp";
import addEpisodeImg from "../img/add_episode.webp";
import ApplePodcastsImg from "../img/apple_podcasts.webp";
import copyRssImg from "../img/copy_rss.webp";

export default function LandingPageDemos() {
  return (
    <Box sx={{ marginTop: "8rem", marginBottom: "8rem" }}>
      <Typography variant="h2">
        Turn your text into professional podcasts
      </Typography>
      <Typography variant="subtitle2" component="p" sx={{ mb: "4rem" }}>
        Create, edit, and publish high-quality podcast episodes with just a few
        clicks. No audio expertise needed.
      </Typography>

      <FeatureDemo
        title="Create episodes from text"
        description="Simply paste or type any text to create a new episode. Choose from a selection of natural-sounding voices in Swedish and English. Your episode will be automatically generated and added to your podcast."
        image={addEpisodeImg}
        imageAlt="Add Episode Screenshot"
        imageOnRight={false}
        lightBackground={true}
      />

      <FeatureDemoSmall
        title="Fine-tune your audio"
        description="Perfect your episode's sound with our paragraph-by-paragraph editor. Adjust intonation, switch voices, and control pause lengths between paragraphs until you get exactly the result you want."
        image={audioEditorImg}
        imageAlt="Audio Editor Screenshot"
        imageOnRight={true}
      />

      <FeatureDemo
        title="Publish when you're ready"
        description="Episodes are hosted on PodReader's platform as soon as they're created, but remain private until you're ready. When satisfied, simply toggle a switch to make your episode public and available in your podcast feed."
        image={existingEpisodesImg}
        imageAlt="Existing Episodes Screenshot"
        imageOnRight={true}
        lightBackground={false}
      />

      <FeatureDemoSmallTwoImages
        title="Share your podcast"
        description="Start listening immediately by copying your private podcast link to any podcast app. Share with friends, or submit your podcast to platforms like Apple Podcasts and Spotify when you're ready to go public."
        image1={copyRssImg}
        image1Alt="Copy RSS Feed Screenshot"
        image2={ApplePodcastsImg}
        image2Alt="Apple Podcasts Screenshot"
      />
    </Box>
  );
}
