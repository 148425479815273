import { makeAuthenticatedRequest } from "./base_requests";

export async function getAudioProject(episode_uuid) {
  return makeAuthenticatedRequest(
    `api/episodes/${episode_uuid}/audio-projects/current/`,
    "GET"
  );
}

export async function generateVoiceSegment(
  episode_uuid,
  audio_project_uuid,
  body
) {
  return makeAuthenticatedRequest(
    `api/episodes/${episode_uuid}/audio-projects/${audio_project_uuid}/generate_segment/`,
    "POST",
    body
  );
}

export async function updatePauseSegment(
  episode_uuid,
  audio_project_uuid,
  body
) {
  return makeAuthenticatedRequest(
    `api/episodes/${episode_uuid}/audio-projects/${audio_project_uuid}/update_pause_segment/`,
    "POST",
    body
  );
}

export async function deleteSegment(episode_uuid, audio_project_uuid, body) {
  return makeAuthenticatedRequest(
    `api/episodes/${episode_uuid}/audio-projects/${audio_project_uuid}/delete_segment/`,
    "DELETE",
    body
  );
}

export async function renderAudioProject(episode_uuid, audio_project_uuid) {
  return makeAuthenticatedRequest(
    `api/episodes/${episode_uuid}/audio-projects/${audio_project_uuid}/render/`,
    "POST"
  );
}

export async function saveAudioProjectToEpisode(
  episode_uuid,
  audio_project_uuid
) {
  return makeAuthenticatedRequest(
    `api/episodes/${episode_uuid}/audio-projects/${audio_project_uuid}/set_as_current/`,
    "POST"
  );
}
