import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import PricingOption from "./PricingOption";
import { PRICING_OPTIONS } from "../constants";

export default function PricingOptions() {
  return (
    <Box>
      <Box sx={{ mb: "2rem" }}>
        <Typography variant="h2">Pricing</Typography>
        <Typography variant="subtitle2" component="p" sx={{ mb: "1rem" }}>
          Buy credits once, use them anytime - no monthly fees or commitments
        </Typography>
        <Typography variant="body1">
          <b>One-time purchase</b>
          <br />
          Purchase credits at your own pace - no subscriptions or commitments.
          Your credits never expire. Need more credits? Simply top up anytime.
          <br />
          <br />
          <b>Credit system</b>
          <br />
          Credits are only used when converting text to audio. Podcast hosting
          is included for free.
          <br />
          <br />
          1,000 credits = 1,000 characters 📝 ≈ 1 minute of audio 🗣️
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <PricingOption
            title={PRICING_OPTIONS.Starter.title}
            subtitle={PRICING_OPTIONS.Starter.subtitle}
            bulletPoints={PRICING_OPTIONS.Starter.bulletPoints}
            price={PRICING_OPTIONS.Starter.price}
            button={{
              href: "/login?signup=true&subscription_type=starter",
              text: "Get PodReader",
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <PricingOption
            title={PRICING_OPTIONS.Standard.title}
            subtitle={PRICING_OPTIONS.Standard.subtitle}
            bulletPoints={PRICING_OPTIONS.Standard.bulletPoints}
            price={PRICING_OPTIONS.Standard.price}
            button={{
              href: "/login?signup=true&subscription_type=standard",
              text: "Get PodReader",
            }}
            highlight={true}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <PricingOption
            title={PRICING_OPTIONS.Pro.title}
            subtitle={PRICING_OPTIONS.Pro.subtitle}
            bulletPoints={PRICING_OPTIONS.Pro.bulletPoints}
            price={PRICING_OPTIONS.Pro.price}
            button={{
              href: "/login?signup=true&subscription_type=pro",
              text: "Get PodReader",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
