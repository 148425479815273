import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import PricingOptions from "./PricingOptions.js";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { slimWidth } from "../constants";
import ApplePodcastEmbed from "./ApplePodcastEmbed.js";
import LandingPageDemos from "./LandingPageDemos.js";

export default function LandingPageContent() {
  const [currentWord, setCurrentWord] = useState("newsletter");
  const [isTransitioning, setIsTransitioning] = useState(false);
  const rotatingWords = ["newsletters", "articles", "blog posts"];
  const { width } = useWindowDimensions();

  useEffect(() => {
    let currentIndex = 0;

    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        currentIndex = (currentIndex + 1) % rotatingWords.length;
        setCurrentWord(rotatingWords[currentIndex]);
        setIsTransitioning(false);
      }, 400);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Typography variant="h1" sx={{ marginTop: "3rem", marginBottom: "1rem" }}>
        {width > slimWidth ? (
          <>
            {"Audio versions of your "}
            <span
              style={{
                opacity: isTransitioning ? 0 : 1,
                transition: "opacity 0.4s ease-in-out",
                display: "inline-block",
              }}
            >
              {currentWord}
            </span>
            <br />
            {" in every podcast app"}
          </>
        ) : (
          <>Audio versions of your text content in every podcast app</>
        )}
      </Typography>
      <Typography
        variant="subtitle1"
        component="p"
        sx={{ marginBottom: "8rem" }}
      >
        Expand your audience of readers by welcoming listeners to enjoy your
        content on the go.
      </Typography>

      <ApplePodcastEmbed />

      <LandingPageDemos />

      <PricingOptions />
    </div>
  );
}
