import React, { useContext } from "react";
import { Typography, Button, Box } from "@mui/material";
import { logout } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import UsageProgressBar from "../components/UsageProgressBar";
import AccountPricingOptions from "../components/AccountPricingOptions";

export default function AccountDetails() {
  const { user } = useContext(UserContext);

  return (
    <Box>
      <Box sx={{ marginBottom: "4rem" }}>
        <Typography variant="h2" sx={{ marginBottom: "0.5rem" }}>
          Account page
        </Typography>
        <Typography variant="body1">
          Welcome back! You're signed in as {user.email}. Here you can view your
          account details and credit balance.
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "4rem" }}>
        <Typography variant="h3" sx={{ mb: "0.5rem" }}>
          Current credit balance
        </Typography>
        <Typography variant="body1" sx={{ mb: "0.5rem" }}>
          Credits are only used when converting text to audio. Podcast hosting
          and other features are included for free.
        </Typography>
        <Typography variant="body1" sx={{ mb: "0.5rem" }}>
          1,000 credits = 1,000 characters 📝 ≈ 1 minute of audio 🗣️
        </Typography>
        <UsageProgressBar />
      </Box>
      <Box sx={{ marginBottom: "4rem" }}>
        <AccountPricingOptions />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={logout}>
          Log out
        </Button>
      </Box>
    </Box>
  );
}
