import React from "react";

const FeedContext = React.createContext({
  feeds: [], // array to store all feeds
  activeFeed: null, // currently active feed
  loadFeeds: () => {}, // function to load all feeds
  newFeed: () => {}, // function to create a new feed
  updateFeed: () => {}, // function to update a feed
  switchFeed: () => {}, // function to switch the active feed
  removeFeed: () => {}, // function to delete a feed
});

export default FeedContext;
