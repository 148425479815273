import React, { useState, useEffect } from "react";
import UserContext from "./UserContext";
import { getUser } from "../api/user";
import { accessTokenExists } from "../api/signup_or_login";

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [userError, setUserError] = useState(null);

  const loadUser = () => {
    if (!accessTokenExists()) {
      setUser(null);
      setUserLoading(false);
      return;
    }
    getUser()
      .then((userData) => {
        setUser(userData);
        setUserError(null);
      })
      .catch((error) => {
        setUserError(error);
      })
      .finally(() => {
        setUserLoading(false);
      });
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, userLoading, userError, loadUser }}>
      {children}
    </UserContext.Provider>
  );
};
