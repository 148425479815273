import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { CardContent, Paper, Typography, Box } from "@mui/material";
import LoggedInPageContainer from "./LoggedInPageContainer";
import AccountDetails from "../components/AccountDetails";
import { StyledCard } from "../atoms/StyledCard";
import UserContext from "../contexts/UserContext";

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const WelcomePaper = styled(Paper)(({ theme }) => ({
  padding: "1rem",
  marginBottom: "2rem",
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  borderWidth: "1px",
  boxShadow: "none",
  borderRadius: "8px",
}));

const NotVerifiedPaper = styled(Paper)(({ theme }) => ({
  padding: "1rem",
  marginBottom: "2rem",
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  borderWidth: "1px",
  boxShadow: "none",
  borderRadius: "8px",
}));

function AccountPageContent() {
  const { user } = useContext(UserContext);
  const urlParams = new URLSearchParams(window.location.search);
  const showWelcome = urlParams.get("welcome")?.toLowerCase() === "true";
  const isVerified = user.account.is_verified;

  return (
    <>
      {showWelcome && isVerified && (
        <WelcomePaper>
          <Box mb={1}>
            <Typography variant="h5" gutterBottom>
              Welcome to PodReader!
            </Typography>
            <Typography>
              You have successfully purchased credits. You can now start
              podcasting!
            </Typography>
          </Box>
        </WelcomePaper>
      )}

      {!isVerified && (
        <NotVerifiedPaper>
          <Box mb={1}>
            <Typography variant="h5" gutterBottom>
              You're almost ready to start podcasting!
            </Typography>
            <Typography>
              First you just need some credits, choose one of the options below.
            </Typography>
          </Box>
        </NotVerifiedPaper>
      )}

      <StyledCard>
        <StyledCardContent>
          <AccountDetails />
        </StyledCardContent>
      </StyledCard>
    </>
  );
}

export default function AccountPage() {
  return (
    <LoggedInPageContainer>
      <AccountPageContent />
    </LoggedInPageContainer>
  );
}
