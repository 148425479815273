import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { slimWidth } from "../constants";

export const LandingPageContainerFullWidth = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "100vw",
  left: "50%",
  right: 0,
  marginLeft: "-50vw",
  marginRight: "-50vw",
  transform: "none",
  height: "100%",
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.secondary.contrastText,
  paddingTop: "8rem",
  paddingBottom: "8rem",
  paddingLeft: "4rem",
  paddingRight: "4rem",
  [theme.breakpoints.down(slimWidth)]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
}));
