import { styled } from "@mui/material/styles";

export const PreviewImage = styled("img")(({ theme }) => ({
  width: "100%",
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  borderRadius: "8px",
  borderWidth: "1px",
  display: "block",
  margin: "auto",
  maxWidth: "150px",

  "@media (max-width: 600px)": {
    maxWidth: "180px",
  },
}));
