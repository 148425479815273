import React, { useContext } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import UserContext from "../contexts/UserContext";
import { styled } from "@mui/material/styles";

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const ProgressLabel = styled(Box)({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

export default function UsageCircularProgress({
  usageCount: propUsageCount,
  usageLimit: propUsageLimit,
}) {
  const { user } = useContext(UserContext);
  const count = propUsageCount ?? user.account.usage_count;
  const limit = propUsageLimit ?? user.account.usage_limit;
  const progress = (count / limit) * 100;

  return (
    <ProgressWrapper>
      <Box position="relative" display="flex" alignItems="center">
        <CircularProgress
          variant="determinate"
          value={100}
          sx={{
            color: (theme) => theme.palette.info.veryLight,
            position: "absolute",
          }}
          size={30}
          thickness={6}
        />
        <CircularProgress
          variant="determinate"
          value={progress}
          color="info"
          size={30}
          thickness={6}
        />
        <ProgressLabel>
          <Typography
            variant="body2"
            component="div"
            sx={{ fontSize: "0.5rem" }}
          >
            {Math.round(progress)}%
          </Typography>
        </ProgressLabel>
      </Box>
      <Typography variant="body2" sx={{ fontSize: "0.6rem" }}>
        {count} / {limit} characters used
      </Typography>
    </ProgressWrapper>
  );
}
