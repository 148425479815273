import React from "react";
import { Box, Typography } from "@mui/material";
import PageContainer from "./PageContainer";

export default function FallbackPage() {
  return (
    <PageContainer>
      <Box sx={{ marginTop: "20vh" }}>
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontSize: 54,
            textAlign: "center",
            marginBottom: 8,
          }}
        >
          404
        </Typography>
      </Box>
    </PageContainer>
  );
}
