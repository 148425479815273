import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import UpdateFeedDetailsForm from "../components/UpdateFeedDetailsForm";
import LoggedInPageContainer from "./LoggedInPageContainer";
import FeedContext from "../contexts/FeedContext";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import UserContext from "../contexts/UserContext";

function FeedSettingsContent() {
  const { uuid } = useParams();
  const { switchFeed, activeFeedUuid, updateFeed } = useContext(FeedContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.account?.is_verified === false) {
      navigate("/account");
      return;
    }
    switchFeed(uuid);
  }, [uuid, navigate]);

  return (
    <Box sx={{ marginBottom: "2rem" }}>
      <UpdateFeedDetailsForm key={activeFeedUuid} onSubmit={updateFeed} />
    </Box>
  );
}

export default function FeedSettingsPage() {
  return (
    <LoggedInPageContainer>
      <FeedSettingsContent />
    </LoggedInPageContainer>
  );
}
