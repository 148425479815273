import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { headerHeight } from "../constants";

export const ModalContentContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: `calc(${headerHeight}px + 1rem)`,
  left: "50%",
  transform: "translateX(-50%)",
  width: "calc(100% - 2rem)",
  height: `calc(100% - ${headerHeight}px - 2rem)`,
  maxWidth: "1100px",
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  borderWidth: "1px",
  boxShadow: "none",
  backgroundColor: theme.palette.background.white,
  borderRadius: theme.shape.borderRadius,
  padding: "1rem",
  boxSizing: "border-box",
}));
