import React, { useState, useContext, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  CardContent,
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { StyledCard } from "../atoms/StyledCard";
import { PreviewImage } from "../atoms/PreviewImage";
import FeedContext from "../contexts/FeedContext";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useNavigate } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { FEED_LANGUAGE_CHOICES, APPLE_PODCAST_CATEGORIES } from "../constants";

const StyledForm = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const FormItem = styled(Box)({
  width: "100%",
});

const ImageUploadContainer = styled(Box)({
  position: "relative",
  width: "fit-content",
  margin: "auto",
  cursor: "pointer",
  "&:hover .overlay": {
    opacity: 1,
  },
  "@media (min-width: 600px)": {
    alignSelf: "flex-start",
    margin: "0",
  },
});

const ImageOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0.7,
  transition: "opacity 0.3s",
  borderRadius: "8px",
  color: "white",
  cursor: "pointer",
});

const HiddenInput = styled("input")({
  display: "none",
});

export default function UpdateFeedDetailsForm({ onSubmit }) {
  const { activeFeed, removeFeed } = useContext(FeedContext);
  const [name, setName] = useState(activeFeed?.name || "");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(activeFeed?.image || null);
  const [author, setAuthor] = useState(activeFeed?.author || "");
  const [summary, setSummary] = useState(activeFeed?.summary || "");
  const [contactEmail, setContactEmail] = useState(
    activeFeed?.contact_email || ""
  );
  const [language, setLanguage] = useState(activeFeed?.language || "");
  const [category, setCategory] = useState(activeFeed?.category || "");
  const [successSubmittingChanges, setSuccessSubmittingChanges] =
    useState(false);
  const [errorSubmittingChanges, setErrorSubmittingChanges] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  useEffect(() => {
    const formHasChanges =
      name !== (activeFeed?.name || "") ||
      author !== (activeFeed?.author || "") ||
      image !== null ||
      summary !== (activeFeed?.summary || "") ||
      contactEmail !== (activeFeed?.contact_email || "") ||
      language !== (activeFeed?.language || "") ||
      category !== (activeFeed?.category || "");

    setHasChanges(formHasChanges);
  }, [
    name,
    author,
    image,
    summary,
    contactEmail,
    language,
    category,
    activeFeed,
  ]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const img = new Image();
      img.onload = () => {
        // Validation
        if (img.width !== img.height) {
          alert("Image must be square.");
          return;
        }
        if (img.width < 1400 || img.width > 3000) {
          alert("Image size must be between 1400x1400 and 3000x3000 pixels.");
          return;
        }
        if (!["image/jpeg", "image/png"].includes(file.type)) {
          alert("Image must be a JPEG or PNG.");
          return;
        }
        // Set image and preview
        setImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create FormData to handle both the name and image upload
    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (author) {
      formData.append("author", author);
    }
    if (image) {
      formData.append("image", image);
    }
    if (summary) {
      formData.append("summary", summary);
    }
    if (contactEmail) {
      formData.append("contact_email", contactEmail);
    }
    if (language) {
      formData.append("language", language);
    }
    if (category) {
      formData.append("category", category);
    }

    onSubmit(formData)
      .then(() => {
        setSuccessSubmittingChanges(true);
        setImage(null);
      })
      .catch((error) => {
        setErrorSubmittingChanges(true);
      });
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSubmittingChanges(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSubmittingChanges(false);
  };

  const handleDeleteFeed = () => {
    removeFeed(activeFeed.uuid).then(() => {
      navigate("/feeds");
    });
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h3" gutterBottom>
          Podcast settings
        </Typography>

        <StyledForm
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <ImageUploadContainer onClick={handleImageClick}>
            <PreviewImage src={preview} alt="Click to change image" />
            <ImageOverlay className="overlay">
              <PhotoCameraIcon sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="body2">
                {preview ? "Change Image" : "Upload Image"}
              </Typography>
            </ImageOverlay>
            <HiddenInput
              ref={fileInputRef}
              type="file"
              onChange={handleImageChange}
              accept="image/*"
            />
          </ImageUploadContainer>

          <Typography variant="caption" sx={{ mt: "0.5rem", mb: "1rem" }}>
            {
              "Artwork requirements: Square JPEG or PNG image, 1400x1400 to 3000x3000 pixels, without transparent background. "
            }
            <a
              href="https://podcasters.apple.com/support/896-artwork-requirements"
              target="_blank"
              rel="noopener noreferrer"
            >
              More details
            </a>
          </Typography>

          <TextField
            id="feed-name-input"
            label="Podcast name"
            type="text"
            sx={{ mt: "1rem" }}
            value={name}
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />

          <FormItem sx={{ marginTop: "1rem" }}>
            <FormControl fullWidth>
              <InputLabel id="language-select-label">Language</InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                label="Language"
                fullWidth
              >
                {Object.entries(FEED_LANGUAGE_CHOICES).map(
                  ([languageId, language_name]) => (
                    <MenuItem key={languageId} value={languageId}>
                      {language_name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </FormItem>

          <FormItem sx={{ marginTop: "1rem" }}>
            <FormControl fullWidth>
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label="Category"
                fullWidth
              >
                {APPLE_PODCAST_CATEGORIES.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormItem>

          <TextField
            id="feed-author-input"
            label="Author"
            type="text"
            sx={{ mt: "1rem" }}
            value={author}
            fullWidth
            onChange={(e) => setAuthor(e.target.value)}
          />

          <TextField
            id="feed-contact-email-input"
            label="Contact email"
            type="email"
            sx={{ mt: "1rem" }}
            value={contactEmail}
            fullWidth
            onChange={(e) => setContactEmail(e.target.value)}
          />

          <TextField
            id="feed-summary-input"
            label="Summary"
            multiline
            rows={4}
            sx={{ mt: "1rem" }}
            value={summary}
            fullWidth
            onChange={(e) => setSummary(e.target.value)}
          />

          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}
          >
            <Button
              type="button"
              variant="outlined"
              onClick={handleDeleteClick}
              startIcon={<WarningIcon />}
              color="error"
            >
              Delete podcast
            </Button>
            <Button type="submit" variant="contained" disabled={!hasChanges}>
              Save changes
            </Button>
          </Box>
        </StyledForm>
      </CardContent>
      <Snackbar
        open={successSubmittingChanges}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert severity="success" onClose={handleCloseSuccess}>
          <AlertTitle>Successfully updated podcast settings!</AlertTitle>
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSubmittingChanges}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert severity="error" onClose={handleCloseError}>
          <AlertTitle>Error updating podcast settings. Try again.</AlertTitle>
        </Alert>
      </Snackbar>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Delete Podcast</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this podcast? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDeleteFeed} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
}
