import React, { useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { CardContent, ToggleButton, ToggleButtonGroup } from "@mui/material";
import LoginForm from "../components/LoginForm";
import SignupForm from "../components/SignupForm";
import { isLoggedIn } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import PageContainer from "./PageContainer";
import { useNavigate } from "react-router-dom";
import { StyledCard } from "../atoms/StyledCard";

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "400px",
});

export default function LoginPage() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const signup = urlParams.get("signup") === "true";

  const [formMode, setFormMode] = React.useState(signup ? "signup" : "login");

  const handleFormModeChange = (event, newMode) => {
    if (newMode !== null) {
      setFormMode(newMode);
    }
  };

  useEffect(() => {
    if (isLoggedIn(user)) {
      navigate("/account");
    }
  }, [user]);

  return (
    <PageContainer>
      <StyledCard sx={{ marginTop: "3rem" }}>
        <StyledCardContent>
          <ToggleButtonGroup
            value={formMode}
            exclusive
            onChange={handleFormModeChange}
          >
            <ToggleButton value="signup">Sign Up</ToggleButton>
            <ToggleButton value="login">Log in</ToggleButton>
          </ToggleButtonGroup>
          {formMode === "signup" ? <SignupForm /> : <LoginForm />}
        </StyledCardContent>
      </StyledCard>
    </PageContainer>
  );
}
