import { styled } from "@mui/material/styles";
import {
  headerHeight,
  footerHeight,
  topMargin,
  bottomMargin,
} from "../constants";

export const MainPageContainer = styled("div")(({ theme }) => ({
  marginTop: topMargin,
  marginBottom: bottomMargin,
  height: "100%",
  width: "100%",
  minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px - ${topMargin}px - ${bottomMargin}px)`,
}));
