import { Typography, Grid2 as Grid, Box } from "@mui/material";
import { LandingPageContainerFullWidth } from "../atoms/LandingPageContainerFullWidth";
import { styled } from "@mui/material/styles";
import { landingPageContainerWideWidth } from "../constants";

const DemoImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  "& img": {
    width: "100%",
    height: "auto",
    borderRadius: "4px",
  },
}));

export default function FeatureDemo({
  title,
  description,
  image,
  imageAlt,
  imageOnRight = true,
  lightBackground = false,
}) {
  const contentOrder = imageOnRight ? { xs: 1, md: 1 } : { xs: 1, md: 2 };
  const imageOrder = imageOnRight ? { xs: 2, md: 2 } : { xs: 2, md: 1 };
  const textAlignment = imageOnRight ? "right" : "left";

  return (
    <LandingPageContainerFullWidth
      sx={{
        backgroundColor: lightBackground
          ? "background.default"
          : "background.dark",
        paddingTop: lightBackground ? "4rem" : "8rem",
        paddingBottom: lightBackground ? "4rem" : "8rem",
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="center"
        sx={{ width: landingPageContainerWideWidth }}
      >
        <Grid size={{ xs: 12, md: 5 }} order={contentOrder}>
          <Typography
            variant="h2"
            sx={{
              mb: 2,
              textAlign: { xs: "center", md: textAlignment },
              color: lightBackground
                ? "primary.main"
                : "text.secondary.contrastText",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            component="p"
            sx={{
              color: lightBackground
                ? "primary.main"
                : "text.secondary.contrastText",
              textAlign: { xs: "center", md: textAlignment },
            }}
          >
            {description}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 7 }} order={imageOrder}>
          <DemoImage>
            <img src={image} alt={imageAlt} width="100%" height="auto" />
          </DemoImage>
        </Grid>
      </Grid>
    </LandingPageContainerFullWidth>
  );
}
