import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  footerHeight,
  BUG_REPORT_FORM_URL,
  FEATURE_REQUEST_FORM_URL,
  CONTACT_FORM_URL,
} from "../constants";

const StyledFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  left: 0,
  right: 0,
  width: "100%",
  height: footerHeight,
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: "1rem",
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
}));

export default function Footer() {
  return (
    <StyledFooter>
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Typography
          variant="body2"
          component="a"
          href={BUG_REPORT_FORM_URL}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "inherit",
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          Report a Bug
        </Typography>
        <Typography
          variant="body2"
          component="a"
          href={FEATURE_REQUEST_FORM_URL}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "inherit",
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          Request a Feature
        </Typography>
        <Typography
          variant="body2"
          component="a"
          href={CONTACT_FORM_URL}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "inherit",
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          Contact Us
        </Typography>
      </Box>
      <Typography variant="body2">© 2024 PodReader</Typography>
    </StyledFooter>
  );
}
