import React, { useContext, useEffect } from "react";
import { isLoggedIn } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import FeedContext from "../contexts/FeedContext";
import { useNavigate } from "react-router-dom";
import { MainPageContainer } from "../atoms/MainPageContainer";
import PageLoading from "../components/PageLoading";

export default function LoggedInPageContainer({ children }) {
  const navigate = useNavigate();

  const { user, userLoading } = useContext(UserContext);
  const { feedLoading } = useContext(FeedContext);

  useEffect(() => {
    if (userLoading || feedLoading) return;

    if (!isLoggedIn(user)) {
      navigate("/login");
      return;
    }

    if (
      user?.account &&
      !user.account.is_verified &&
      window.location.pathname !== "/account"
    ) {
      navigate("/account");
      return;
    }
  }, [user, userLoading, feedLoading]);

  if (userLoading || feedLoading || !isLoggedIn(user)) {
    return (
      <MainPageContainer>
        <PageLoading />
      </MainPageContainer>
    );
  }

  return <MainPageContainer>{children}</MainPageContainer>;
}
