import React from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import FallbackPage from "./FallbackPage";
import FeedPage from "./FeedPage";
import AccountPage from "./AccountPage";
import FeedsPage from "./FeedsPage";
import FeedSettingsPage from "./FeedSettingsPage";
import PublicEpisodePage from "./PublicEpisodePage";

export default function Routes() {
  return (
    <RouterRoutes>
      {/* ---- PUBLIC PAGES ---- */}
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/login" element={<LoginPage />} />
      <Route path="/episode/:uuid" element={<PublicEpisodePage />} />
      {/* ---- LOGGED IN PAGES ---- */}
      <Route exact path="/feeds" element={<FeedsPage />} />
      <Route path="/feed/:uuid" element={<FeedPage />} />
      <Route path="/feed/:uuid/settings" element={<FeedSettingsPage />} />
      <Route exact path="/account" element={<AccountPage />} />
      <Route path="*" element={<FallbackPage />} />
    </RouterRoutes>
  );
}
