import { Typography, Grid2 as Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { landingPageContainerWideWidth } from "../constants";
import { LandingPageContainerWide } from "../atoms/LandingPageContainerWide";

const DemoImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  "& img": {
    width: "100%",
    height: "auto",
    borderRadius: "1rem",
  },
}));

export default function FeatureDemoSmall({
  title,
  description,
  image,
  imageAlt,
  imageOnRight = true,
}) {
  const contentOrder = imageOnRight ? { xs: 1, md: 1 } : { xs: 1, md: 2 };
  const imageOrder = imageOnRight ? { xs: 2, md: 2 } : { xs: 2, md: 1 };
  const textAlignment = imageOnRight ? "right" : "left";

  return (
    <LandingPageContainerWide>
      <Grid
        container
        spacing={4}
        alignItems="center"
        sx={{ width: landingPageContainerWideWidth }}
      >
        <Grid size={{ xs: 12, md: 6 }} order={contentOrder}>
          <Box>
            <Typography
              variant="h2"
              sx={{
                mb: 2,
                textAlign: { xs: "center", md: textAlignment },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle2"
              component="p"
              sx={{
                textAlign: { xs: "center", md: textAlignment },
              }}
            >
              {description}
            </Typography>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} order={imageOrder}>
          <DemoImage>
            <img src={image} alt={imageAlt} width="100%" height="auto" />
          </DemoImage>
        </Grid>
      </Grid>
    </LandingPageContainerWide>
  );
}
