import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import PageContainer from "./PageContainer";
import { getPublicEpisode } from "../api/episode";
import { StyledCard } from "../atoms/StyledCard";
import { PreviewImage } from "../atoms/PreviewImage";
import { slimWidth } from "../constants";

export default function PublicEpisodePage() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [episode, setEpisode] = useState(null);

  useEffect(() => {
    async function fetchEpisode() {
      try {
        const data = await getPublicEpisode(uuid);
        setEpisode(data);
      } catch (error) {
        navigate("/404");
      }
    }
    fetchEpisode();
  }, [uuid, navigate]);

  return (
    <PageContainer>
      {episode && (
        <StyledCard
          sx={{ maxWidth: slimWidth, marginTop: "3rem", padding: "1rem" }}
        >
          <Box sx={{ textAlign: "center" }}>
            <PreviewImage src={episode.feed_image} alt="Feed Image" />
            <Typography variant="subtitle1" color="text.secondary">
              {episode.feed_author}
            </Typography>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ marginTop: "1rem" }}
            >
              {episode.feed_name}
            </Typography>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                marginTop: "0.5rem",
                fontWeight: "bold",
              }}
            >
              {episode.title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ marginTop: "0.25rem", marginBottom: "1rem" }}
            >
              {new Date(episode.created_date).toLocaleDateString()}
            </Typography>
            {episode.audio_url && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                  width: "100%",
                }}
              >
                <audio controls style={{ width: "100%", height: "30px" }}>
                  <source src={episode.audio_url} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </Box>
            )}
          </Box>
        </StyledCard>
      )}
    </PageContainer>
  );
}
