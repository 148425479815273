import React from "react";
import {
  Button,
  Typography,
  CardContent,
  CardActions,
  Box,
} from "@mui/material";
import { StyledCard } from "../atoms/StyledCard";
import CheckIcon from "@mui/icons-material/Check";

export default function PricingOption({
  title,
  subtitle,
  bulletPoints,
  button,
  price,
  highlight,
}) {
  return (
    <StyledCard
      sx={{
        height: "100%",
        borderWidth: "2px",
        borderColor: highlight ? "primary.main" : "secondary.light",
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h3" component="h2" sx={{ mb: "0.5rem" }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            height: "35px",
            mb: "0.5rem",
          }}
        >
          {subtitle}
        </Typography>
        <Box component="ul" sx={{ pl: 0, listStyle: "none" }}>
          {bulletPoints.map((point) => (
            <Box
              component="li"
              key={point}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.2rem",
                mb: "0.5rem",
              }}
            >
              <CheckIcon color="success" fontSize="small" />
              <Typography variant="body2" sx={{ margin: 0 }}>
                {point}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>

      <Box
        sx={{
          mx: "1rem",
          mb: "0.5rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" sx={{ ml: 0, mr: 0 }}>
            €{price}{" "}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "0.6rem", ml: "4px", mr: 0 }}
          >
            excl. taxes
          </Typography>
        </Box>
        <Typography variant="body2" fontWeight="bold" sx={{ mt: "0.2rem" }}>
          One-time purchase
        </Typography>
      </Box>

      <CardActions sx={{ justifyContent: "center", pb: "1rem" }}>
        <Button variant="contained" size="small" href={button.href}>
          {button.text}
        </Button>
      </CardActions>
    </StyledCard>
  );
}
