import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  boxShadow: "none",
  borderRadius: "8px",
  borderWidth: "1px",
}));
