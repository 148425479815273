import { useEffect } from "react";
import { getEpisode } from "../api/episode";

export default function useEpisodePolling(episodeUuid, feed, onSuccess) {
  useEffect(() => {
    if (!episodeUuid || !feed) return;

    const controller = new AbortController();
    const maxAttempts = 24;
    const POLLING_TIMEOUT_MS = 5000;
    let attempts = 0;

    const pollEpisode = () => {
      if (attempts >= maxAttempts) return;

      getEpisode(feed.uuid, episodeUuid, {
        signal: controller.signal,
      })
        .then((episode) => {
          if (episode?.audio_url) {
            onSuccess();
          } else {
            attempts++;
            setTimeout(pollEpisode, POLLING_TIMEOUT_MS);
          }
        })
        .catch((error) => {
          if (!controller.signal.aborted) {
            console.error("Polling failed:", error);
          }
        });
    };

    pollEpisode();

    return () => controller.abort();
  }, [episodeUuid, feed, onSuccess]);
}
