import React, { useState, useContext } from "react";
import { Typography, Box, Tooltip, IconButton, Grid } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { BASE_URL } from "../constants";
import FeedContext from "../contexts/FeedContext";
import { styled } from "@mui/material/styles";
import { PreviewImage } from "../atoms/PreviewImage";

const FeedLinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0.5rem",
  backgroundColor: theme.palette.secondary.light,
  borderRadius: "4px",
  maxWidth: "100%",
}));

const FeedLinkWrapper = styled(Box)({
  flexGrow: 1,
  flexShrink: 1,
  minWidth: 0,
  overflow: "hidden",
  marginRight: "0.5rem",
});

const FeedLinkScroll = styled(Box)({
  overflowX: "auto",
  whiteSpace: "nowrap",
});

const FeedLink = styled(Typography)({
  textDecoration: "none",
  cursor: "pointer",
});

export default function FeedDetails() {
  const { activeFeed } = useContext(FeedContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyLink = () => {
    const feedUrl = `${BASE_URL}/rss/${activeFeed.uuid}/`;
    navigator.clipboard.writeText(feedUrl).then(() => {
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000); // Auto hide tooltip after 2 seconds
    });
  };

  return (
    <div>
      {activeFeed !== null && (
        <>
          <Grid container spacing={3} sx={{ mb: "2rem" }}>
            <Grid item xs={12} sm={4} md={3}>
              <PreviewImage src={activeFeed.image} alt="Preview" />
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h2" sx={{ m: 0 }}>
                  {activeFeed.name}
                </Typography>
                <FeedLinkContainer sx={{ mt: "0.5rem" }}>
                  <FeedLinkWrapper>
                    <FeedLinkScroll>
                      <FeedLink
                        component="span"
                        onClick={handleCopyLink}
                        color="info"
                      >
                        {`${BASE_URL}/rss/${activeFeed.uuid}/`}
                      </FeedLink>
                    </FeedLinkScroll>
                  </FeedLinkWrapper>
                  <Tooltip
                    title="Link copied to clipboard!"
                    open={tooltipOpen}
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                    arrow
                  >
                    <IconButton
                      onClick={handleCopyLink}
                      sx={{ flexShrink: 0 }}
                      size="small"
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </FeedLinkContainer>
                <Typography variant="caption">
                  Copy and paste this link into your podcast app to listen.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
