import React, { useContext } from "react";
import { Grid, CardContent, Typography, CardMedia } from "@mui/material";
import FeedContext from "../contexts/FeedContext";
import { useNavigate } from "react-router-dom";
import { StyledCard } from "../atoms/StyledCard";

const FeedsGrid = () => {
  const navigate = useNavigate();

  const { feeds } = useContext(FeedContext);

  return (
    <div>
      <Grid container spacing={3}>
        {feeds.map((feed) => (
          <Grid item xs={12} sm={6} md={4} key={feed.uuid}>
            <StyledCard
              onClick={() => navigate(`/feed/${feed.uuid}`)}
              sx={{ cursor: "pointer", height: "100%" }}
            >
              <CardMedia
                sx={{
                  height: 0,
                  paddingTop: "56.25%",
                }}
                image={feed.image}
                title={feed.name}
              />
              <CardContent
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography>{feed.name}</Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FeedsGrid;
