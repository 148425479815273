import React, { useContext } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { PRICING_OPTIONS } from "../constants";
import PricingOption from "./PricingOption";
import UserContext from "../contexts/UserContext";
import { getStripeUrl } from "./SignupForm";

export default function AccountPricingOptions() {
  const { user } = useContext(UserContext);

  return (
    <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <Box sx={{ marginBottom: "2rem" }}>
        <Typography variant="h3" sx={{ mb: "0.5rem" }}>
          Purchase More Credits
        </Typography>
        <Typography variant="body1">
          When you run out of credits, you can purchase more here and they will
          be added to your current balance.
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <PricingOption
            title={PRICING_OPTIONS.Starter.title}
            subtitle={PRICING_OPTIONS.Starter.subtitle}
            bulletPoints={PRICING_OPTIONS.Starter.bulletPoints}
            price={PRICING_OPTIONS.Starter.price}
            button={{
              href: getStripeUrl("starter", user.email),
              text: "Get credits",
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <PricingOption
            title={PRICING_OPTIONS.Standard.title}
            subtitle={PRICING_OPTIONS.Standard.subtitle}
            bulletPoints={PRICING_OPTIONS.Standard.bulletPoints}
            price={PRICING_OPTIONS.Standard.price}
            button={{
              href: getStripeUrl("standard", user.email),
              text: "Get credits",
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <PricingOption
            title={PRICING_OPTIONS.Pro.title}
            subtitle={PRICING_OPTIONS.Pro.subtitle}
            bulletPoints={PRICING_OPTIONS.Pro.bulletPoints}
            price={PRICING_OPTIONS.Pro.price}
            button={{
              href: getStripeUrl("pro", user.email),
              text: "Get credits",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
