import React, { useContext } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import UserContext from "../contexts/UserContext";

function UsageProgressBar() {
  const { user } = useContext(UserContext);
  const usageCount = user.account.usage_count;
  const usageLimit = user.account.usage_limit;
  const progress = (usageCount / usageLimit) * 100;

  return (
    <Box sx={{ height: "55px", width: "100%" }}>
      <Typography variant="body1">Usage:</Typography>
      <LinearProgress
        sx={{ height: "0.5rem" }}
        variant="determinate"
        value={progress}
        color="info"
      />
      <Typography variant="body2">
        {usageCount} / {usageLimit} characters
      </Typography>
    </Box>
  );
}

export default UsageProgressBar;
