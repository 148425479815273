import { Typography, Grid2 as Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { landingPageContainerWideWidth } from "../constants";
import { LandingPageContainerWide } from "../atoms/LandingPageContainerWide";

const DemoImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  "& img": {
    width: "100%",
    height: "auto",
    borderRadius: "1rem",
  },
}));

export default function FeatureDemoSmallTwoImages({
  title,
  description,
  image1,
  image1Alt,
  image2,
  image2Alt,
}) {
  return (
    <LandingPageContainerWide sx={{ backgroundColor: "background.dark" }}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        sx={{ width: landingPageContainerWideWidth }}
      >
        <Grid size={{ xs: 12, md: 7 }} order={{ xs: 1, md: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Box>
              <Typography
                variant="h2"
                sx={{
                  mb: 2,
                  textAlign: { xs: "center", md: "right", color: "white" },
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="subtitle2"
                component="p"
                sx={{
                  textAlign: { xs: "center", md: "right", color: "white" },
                }}
              >
                {description}
              </Typography>
            </Box>
            <DemoImage>
              <img src={image1} alt={image1Alt} width="100%" height="auto" />
            </DemoImage>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 5 }} order={{ xs: 2, md: 2 }}>
          <DemoImage>
            <img src={image2} alt={image2Alt} width="100%" height="auto" />
          </DemoImage>
        </Grid>
      </Grid>
    </LandingPageContainerWide>
  );
}
