import React from "react";
import LoggedInPageContainer from "./LoggedInPageContainer";
import FeedsGrid from "../components/FeedsGrid";

export default function FeedsPage() {
  return (
    <LoggedInPageContainer>
      <FeedsGrid />
    </LoggedInPageContainer>
  );
}
