import { makeAuthenticatedRequest } from "./base_requests";

export async function addEpisode(feed_uuid, body) {
  return makeAuthenticatedRequest(
    `api/feeds/${feed_uuid}/episodes/`,
    "POST",
    body
  );
}

export async function deleteEpisode(feed_uuid, episode_uuid) {
  return makeAuthenticatedRequest(
    `api/feeds/${feed_uuid}/episodes/${episode_uuid}/`,
    "DELETE"
  );
}

export async function listEpisodes(feed_uuid) {
  return makeAuthenticatedRequest(`api/feeds/${feed_uuid}/episodes/`, "GET");
}

export async function updateEpisode(feed_uuid, episode_uuid, body) {
  return makeAuthenticatedRequest(
    `api/feeds/${feed_uuid}/episodes/${episode_uuid}/`,
    "PATCH",
    body
  );
}

export async function getEpisode(feed_uuid, episode_uuid) {
  return makeAuthenticatedRequest(
    `api/feeds/${feed_uuid}/episodes/${episode_uuid}/`,
    "GET"
  );
}

export async function getPublicEpisode(episode_uuid) {
  const response = await fetch(`/api/episode/${episode_uuid}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}
