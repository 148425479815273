import React, { useContext } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ELEVEN_LABS_VOICE_NAMES,
  MIN_CHARS_PER_EPISODE,
  MAX_CHARS_PER_SEGMENT,
} from "../constants";
import AudioControls from "./AudioControls";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import theme from "../theme";
import UserContext from "../contexts/UserContext";

const SegmentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.white,
  marginBottom: theme.spacing(2),
}));

const Controls = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 2,
  marginTop: 2,
  width: "100%",
  justifyContent: "center",
});

function PauseSegment({
  segment,
  segmentIndex,
  onUpdatePauseSegment,
  onDelete,
  anyActionInProgress,
  setAnyActionInProgress,
}) {
  const [durationSeconds, setDurationSeconds] = React.useState(
    segment.duration_seconds
  );
  const [tempInput, setTempInput] = React.useState(segment.duration_seconds);
  const [inputsHaveChanged, setInputsHaveChanged] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);

  React.useEffect(() => {
    const hasUnsavedChanges = durationSeconds !== segment.duration_seconds;

    setAnyActionInProgress(hasUnsavedChanges);
    setInputsHaveChanged(hasUnsavedChanges);
  }, [durationSeconds, segment.duration_seconds, setAnyActionInProgress]);

  const handleUpdate = async () => {
    setIsUpdating(true);
    await onUpdatePauseSegment(segmentIndex, durationSeconds);
    setIsUpdating(false);
  };

  const handleDisregardInputChanges = () => {
    setDurationSeconds(segment.duration_seconds);
    setTempInput(segment.duration_seconds);
    setInputsHaveChanged(false);
  };

  const handleDurationChange = (e) => {
    const rawValue = e.target.value;
    if (rawValue === "") {
      setTempInput("");
      setAnyActionInProgress(true);
      return;
    }

    const value = parseFloat(rawValue);
    if (!isNaN(value)) {
      const clampedValue = Math.max(0, Math.min(value, 10));
      setTempInput(clampedValue);
      setDurationSeconds(clampedValue);
      setAnyActionInProgress(true);
    }
  };

  const handleBlur = () => {
    if (tempInput === "") {
      setTempInput(durationSeconds);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <SegmentContainer
        sx={{
          border: inputsHaveChanged
            ? `2px solid ${theme.palette.primary.main}`
            : `2px solid ${theme.palette.primary.contrastText}`,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ mb: "1rem", textAlign: "center", fontSize: "1rem" }}
        >
          Pause duration
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            "@media (max-width: 600px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              width: "100px",
              display: "flex",
              justifyContent: "flex-end",
              "@media (max-width: 600px)": {
                justifyContent: "center",
              },
            }}
          >
            <IconButton
              onClick={handleDisregardInputChanges}
              disabled={!inputsHaveChanged || isUpdating}
              size="small"
              color="primary"
            >
              <RestartAltIcon />
            </IconButton>
          </Box>
          <TextField
            type="number"
            label="Duration"
            value={tempInput}
            onChange={handleDurationChange}
            onBlur={handleBlur}
            inputProps={{
              min: 0,
              max: 10,
              step: 0.1,
            }}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">seconds</InputAdornment>
                ),
              },
            }}
            size="small"
            disabled={anyActionInProgress && !inputsHaveChanged}
          />
          <Box
            sx={{
              width: "100px",
              display: "flex",
              justifyContent: "flex-start",
              "@media (max-width: 600px)": {
                justifyContent: "center",
              },
            }}
          >
            <Button
              variant="contained"
              size="small"
              disabled={!inputsHaveChanged || isUpdating}
              onClick={handleUpdate}
            >
              {isUpdating ? "Updating..." : "Update"}
            </Button>
          </Box>
        </Box>
      </SegmentContainer>
    </Box>
  );
}

function VoiceSegment({
  segment,
  segmentIndex,
  onDelete,
  onGenerateSegment,
  anyActionInProgress,
  setAnyActionInProgress,
}) {
  const { user } = useContext(UserContext);
  const usageCount = user.account.usage_count;
  const usageLimit = user.account.usage_limit;
  const usageLeft = usageLimit - usageCount;
  const minCharsToAdd = Math.min(segment.text.length, MIN_CHARS_PER_EPISODE);
  const maxCharsToAdd = Math.min(usageLeft, MAX_CHARS_PER_SEGMENT);

  const [text, setText] = React.useState(segment.text);
  const [voiceId, setVoiceId] = React.useState(segment.voice_id);
  const [inputsHaveChanged, setInputsHaveChanged] = React.useState(false);
  const [isGenerating, setIsGenerating] = React.useState(false);

  React.useEffect(() => {
    const hasUnsavedChanges =
      text !== segment.text || voiceId !== segment.voice_id;

    // Globally inputs have changed
    setAnyActionInProgress(hasUnsavedChanges);
    // Locally inputs have changed
    setInputsHaveChanged(hasUnsavedChanges);
  }, [text, voiceId, segment.text, segment.voice_id, setAnyActionInProgress]);

  const handleGenerate = async () => {
    setIsGenerating(true);
    await onGenerateSegment(segmentIndex, text, voiceId);
    setIsGenerating(false);
  };

  const handleDisregardInputChanges = () => {
    setText(segment.text);
    setVoiceId(segment.voice_id);
    setInputsHaveChanged(false);
  };

  const handleTextChange = (event) => {
    event.preventDefault();
    const numChars = event.target.value.length;

    if (usageLeft < minCharsToAdd) {
      return;
    }
    if (numChars > maxCharsToAdd) {
      return;
    }
    setText(event.target.value);
  };

  return (
    <SegmentContainer
      sx={{
        border: inputsHaveChanged
          ? `2px solid ${theme.palette.primary.main}`
          : `2px solid ${theme.palette.primary.contrastText}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <FormControl sx={{ margin: 0 }}>
          <InputLabel id="voice-select-label">Voice</InputLabel>
          <Select
            labelId="voice-select-label"
            id="voice-select"
            value={voiceId}
            onChange={(e) => setVoiceId(e.target.value)}
            size="small"
            label="Voice"
            disabled={anyActionInProgress && !inputsHaveChanged}
          >
            {Object.entries(ELEVEN_LABS_VOICE_NAMES).map(
              ([voiceId, voice_name]) => (
                <MenuItem key={voiceId} value={voiceId}>
                  {voice_name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>

        <TextField
          sx={{
            width: "100%",
            maxWidth: "100%",
            margin: 0,
            "& .MuiInputBase-root": {
              margin: 0,
              width: "100%",
              maxWidth: "100%",
            },
          }}
          multiline
          label="Text"
          value={text}
          onChange={handleTextChange}
          disabled={anyActionInProgress && !inputsHaveChanged}
          helperText={`${text.length} of max ${maxCharsToAdd} characters (minimum ${minCharsToAdd})`}
        />

        <Controls>
          <Box
            sx={{ width: "150px", display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              onClick={handleDisregardInputChanges}
              disabled={!inputsHaveChanged || isGenerating}
              size="small"
              color="primary"
            >
              <RestartAltIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              marginLeft: "1rem",
              marginRight: "1rem",
              "@media (max-width: 600px)": {
                marginLeft: 0,
                marginRight: 0,
              },
            }}
          >
            {segment.audio_url && (
              <AudioControls audioUrl={segment.audio_url} />
            )}
          </Box>
          <Box
            sx={{
              width: "150px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              variant="contained"
              onClick={handleGenerate}
              size="small"
              disabled={
                !inputsHaveChanged ||
                isGenerating ||
                text.length < minCharsToAdd
              }
            >
              {isGenerating ? "Generating..." : "Generate"}
            </Button>
          </Box>
        </Controls>
      </Box>
    </SegmentContainer>
  );
}

export default function AudioSegment({
  segment,
  segmentIndex,
  onGenerateSegment,
  onUpdatePauseSegment,
  onDelete,
  anyActionInProgress,
  setAnyActionInProgress,
}) {
  if (segment.type === "pause") {
    return (
      <PauseSegment
        segment={segment}
        segmentIndex={segmentIndex}
        onUpdatePauseSegment={onUpdatePauseSegment}
        onDelete={onDelete}
        anyActionInProgress={anyActionInProgress}
        setAnyActionInProgress={setAnyActionInProgress}
      />
    );
  }
  return (
    <VoiceSegment
      segment={segment}
      segmentIndex={segmentIndex}
      onGenerateSegment={onGenerateSegment}
      onDelete={onDelete}
      anyActionInProgress={anyActionInProgress}
      setAnyActionInProgress={setAnyActionInProgress}
    />
  );
}
