import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import EpisodeList from "../components/EpisodeList";
import FeedDetails from "../components/FeedDetails";
import LoggedInPageContainer from "./LoggedInPageContainer";
import FeedContext from "../contexts/FeedContext";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import UserContext from "../contexts/UserContext";

function FeedContent() {
  const { uuid } = useParams();
  const { switchFeed, activeFeedUuid } = useContext(FeedContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.account?.is_verified === false) {
      navigate("/account");
      return;
    }
    switchFeed(uuid);
  }, [uuid, navigate]);

  return (
    <>
      <Box sx={{ marginBottom: "2rem" }}>
        <FeedDetails key={activeFeedUuid} />
      </Box>
      <Box sx={{ marginBottom: "2rem" }}>
        <EpisodeList key={activeFeedUuid} />
      </Box>
    </>
  );
}

export default function FeedPage() {
  return (
    <LoggedInPageContainer>
      <FeedContent />
    </LoggedInPageContainer>
  );
}
