import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Button, Box, TextField } from "@mui/material";
import { signupOrLogin } from "../api/signup_or_login";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";
import FeedContext from "../contexts/FeedContext";

const FormContainer = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const FormItem = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  maxWidth: "300px",
  width: "100%",
}));

const ErrorMessage = styled(Box)({
  color: "red",
  marginBottom: "10px",
});

export default function LoginForm() {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const { loadUser } = useContext(UserContext);
  const { loadFeeds } = useContext(FeedContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    signupOrLogin(email, password, false)
      .then(() => {
        loadUser();
        loadFeeds();
        navigate("/feeds");
      })
      .catch((error) => {
        setErrorMessage(
          error.message || "An error occurred during login. Please try again."
        );
      });
  };

  return (
    <FormContainer
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <FormItem>
        <TextField
          required
          id="email-input"
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          fullWidth
        />
      </FormItem>

      <FormItem>
        <TextField
          required
          id="password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
      </FormItem>

      <FormItem sx={{ display: "flex", justifyContent: "center" }}>
        <Button type="submit" variant="contained">
          Login
        </Button>
      </FormItem>
    </FormContainer>
  );
}
